<template>
  <transition>
    <div id="itemDetails">
      <v-btn class="close-modal"
             elevation="0"
             fab
             small
             @click="$router.go(-1)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-img
          :lazy-src="item.image ? item.image.filepath : ''"
          :src="item.image ? item.image.filepath : ''"
          max-height="500"
          max-width="100%"></v-img>
      <v-card
          height="300"
          outlined
          shaped
          class="item-details-data">
        <v-container>
          <v-card-title class="pb-0">{{item.name}}</v-card-title>
          <div class="d-flex">
            <v-col cols="8"
            >
              <v-card-subtitle class="pa-0">{{item.description}} </v-card-subtitle>
            </v-col>
            <v-col cols="4">
              <v-card-title class="pl-0 py-0 justify-end">{{( !selectBebida ? item.unit_price: 0) | money}}</v-card-title>
            </v-col>
          </div>
          <div class="d-flex justify-center">
            <v-col cols="6">
          <quantity-selector v-model="quantity"></quantity-selector>
            </v-col>
          </div>

        </v-container>
        <div class="text-center item-details-button">
          <v-btn tile elevation="0" block color="orange" class="white--text" large @click="addProduct">Agregar al carrito</v-btn>
        </div>
      </v-card>

    </div>
  </transition>
</template>

<script>
import QuantitySelector from "@/components/restaurant/QuantitySelector";
import {Restaurant} from "@/services/api";
export default {
  name: "ItemDetails",
  components: {QuantitySelector},
  data(){
  return{
    quantity:1
  }
},
  mounted() {
    this.$store.commit('setLoading', false)
  },
  computed:{
    item(){
      return this.$route.params.item;
    },
    selectBebida(){
      return this.$store.state.relatedItem || false
    }
  },
  methods:{
    addProduct(){
      let item_related_id = null
      if(this.selectBebida){
        item_related_id = this.selectBebida
        this.$store.commit('setRelatedItem',false)
      }
      Restaurant(this.$store.state.user.token_type, this.$store.state.user.access_token)
          .addArticle(this.$store.state.order.id, {
            'restaurant_article_id': this.item.id,
            'quantity':this.quantity,
            'item_related_id':item_related_id
          })
          .then(
              response => {
                const selectBebidaData = response.data.data.items.find(
                    elem => elem.id === this.item.id
                )
                //TODO IS MENU! (Return articles of menu?)
                if( this.item.category.is_menu){
                  this.$store.commit('setRelatedItem',selectBebidaData.order_item_id)
                }
                this.$store.commit('setOrder', response.data.data)
                this.$router.go(-1)
              }
          )


    }
  }


}
</script>

<style lang="scss">
#itemDetails{
  .item-details-data{
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
  }
  .item-details-button{
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .close-modal {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }

}

</style>